<template>
  <v-container id="user-management" fluid tag="section">
    <v-card elevation="0">
      <v-toolbar flat>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>{{ $t('user.user') }} </v-toolbar-title>
        <template #extension>
          <v-tabs v-model="currentTab" align-with-title>
            <v-tab v-for="tab in tabs" :key="tab.content">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="tab in tabs" :key="tab.content">
          <v-card flat>
            <v-card-text>
              <component :is="tab.content" context="userManagement" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    logout: () => import('./components/UserLogout.vue')
  },
  data: function() {
    return {
      currentTab: null
    };
  },
  computed: {
    tabs() {
      return [{ name: this.$t('user.logout'), content: 'logout' }];
    }
  },

  watch: {},

  mounted() {}
};
</script>
